<template>
  <section class="CorePicturePopin">
    <v-card flat outlined>
      <v-card-text>
        <v-img contain :src="currentPicture" class="limitSize" />
      </v-card-text>
      <v-btn class="closeButton" icon @click="togglePoppinPicture"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-card>
  </section>
</template>

<script>
export default {
  name: "CorePicturePopin",
  props: ["currentPicture"],
  methods: {
    togglePoppinPicture() {
      this.$emit("togglePoppinPicture", false);
    },
  },
  mounted() {
    this.$root.$on("closeCurrent", this.togglePoppinPicture);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";
.CorePicturePopin {
  @extend %popinView;
  .v-image {
    max-width: 90vw;
    max-height: 90vh;
  }
  .v-card__text {
    display: flex;
    justify-content: center !important;
    align-content: center !important;
    padding-top: 45px;
  }
  .v-card {
    padding: 0;
    width: unset;
  }
  .closeButton {
    position: absolute;
    top: 5px;
    right: 10px;
    &:hover {
      color: red;
    }
  }
}
</style>
